<template>
  <v-row justify="center">
    <v-dialog 
    v-model="show"
    scrollable
    persistent
    max-width="650">
        <v-card>
            <!-- <v-card-text class="pa-0" style="max-height:250px">
                <v-list class=" pa-0">
                    <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title style="font-size:large;" class="font-weight-bold">
                        <v-icon size="35" dense dark :color="color.theme" class="pr-2">mdi-bullhorn</v-icon>
                        รายละเอียด
                        </v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text> -->
            <!-- <v-icon :color="color.theme" dense dark size="35">mdi-bullhorn</v-icon> -->
            <v-card-title>
                <v-icon :color="color.theme" dense dark size="35" class="pr-3">mdi-bullhorn</v-icon>
                {{$t('default') === 'en' ? 'Detail' : 'รายละเอียด'}}</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-3 px-10" style="height: 700px;">
                <v-layout row>
                    <v-flex xl6 lg6 md6 sm6 xs12 pa-2>
                        <b>{{$t('default') === 'en' ? 'Name' : 'ชื่อผู้ใช้งาน'}}</b>
                        <v-text-field 
                        v-model.trim="username_rp"
                        outlined
                        solo
                        flat
                        :disabled="isloadingUpload"
                        :error-messages="username_report"
                        @blur="$v.username_rp.$touch()"
                        color="#174966"
                        prepend-inner-icon="account_box"
                        dense
                        class="pt-2"
                        > 
                        <template v-slot:append>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                                mdi-help-circle-outline
                            </v-icon>
                            </template>
                            {{$t('default') === 'en' ? 'Please fill full name' : 'โปรดระบุชื่อจริงผู้ใช้งาน'}} <br/>{{ $t('default') === 'en' ? 'for identification' : 'เพื่อใช้ในการระบุตัวตน'}}
                        </v-tooltip>
                        </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xl6 lg6 md6 sm6 xs12 pa-2 class="mt-xs-n5">
                        <b>{{$t('default') === 'en' ? 'Phone Number' : 'เบอร์โทรศัพท์'}}</b>
                        <v-text-field 
                        v-model.trim="phonenumber"
                        outlined
                        @keypress="fn_keyupPhoneNumber($event)"
                        solo
                        flat
                        @paste.prevent
                        @blur="fn_unfocus_phonenumber()"
                        :error-messages="phonenumber_report"
                        :disabled="isloadingUpload"
                        prepend-inner-icon="mdi-phone"
                        color="#174966"
                        dense
                        class="pt-2"
                        />
                    </v-flex>
                    <v-flex xl12 lg12 md12 sm12 xs12 pa-2 class="mt-n5">
                        <b>{{$t('default') === 'en' ? 'Email Contacts' : 'อีเมลสำหรับติดต่อกลับ'}}</b>
                        <v-form ref="formEmail">
                        <v-text-field 
                        :disabled="isloadingUpload"
                        outlined
                        solo
                        flat
                        prepend-inner-icon="mdi-email"
                        color="#174966"
                        dense
                        v-model.trim="email_rp"
                        :error-messages="email_report"
                        @blur="$v.email_rp.$touch()"
                        class="pt-2"
                        > 
                        <template v-slot:append>
                            <v-tooltip bottom class="text-center">
                                <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                    mdi-help-circle-outline
                                </v-icon>
                                </template>
                                {{$t('default') === 'en' ? 'Please fill an email to identification' : 'โปรดระบุอีเมลเพื่อใช้ในการระบุตัวตน'}}
                            </v-tooltip>
                        </template>
                        </v-text-field>
                        </v-form>
                    </v-flex>
                    <v-flex xl12 lg12 md12 sm12 xs12 pa-2  class="mt-n4">
                        <b> {{$t('default') === 'en' ? 'Report Topic' : 'หัวข้อปัญหา'}}</b>
                        <v-combobox
                        :disabled="isloadingUpload"
                        v-model="selectedTopic_default"
                        outlined
                        auto-select-first
                        color="#174966"
                        dense
                        :error-messages="topic_report"
                        item-value="case_id"
                        :item-text="$t('default') === 'en' ? 'case_name_en': 'case_name_th'"
                        :return-object="true"
                        :items="topicCase"
                        prepend-inner-icon="mdi-bullhorn"
                        class="pt-2"
                        >
                        <template v-slot:item="{item}">
                            <span>
                                {{ $t('default') === 'en' ? item.case_name_en : item.case_name_th }}
                            </span>
                        </template>
                        <template v-slot:selection="{item}" >
                            <span>
                                {{ $t('default') === 'en' ? item.case_name_en : item.case_name_th }}
                            </span>
                        </template>
                        </v-combobox>
                    </v-flex>
                    <v-flex xl12 lg12 md12 sm12 xs12 pa-2 class="mt-n5">
                        <b>{{$t('default') === 'en' ? 'Detail' : 'รายละเอียดเพิ่มเติม'}}</b>
                        <v-textarea
                        :disabled="isloadingUpload"
                        :error-messages="detail_report"
                        @blur="$v.detail_rp.$touch()"
                        v-model.trim="detail_rp"
                        outlined
                        auto-grow
                        solo
                        flat
                        clear-icon="mdi-close"
                        class="pt-2"
                        counter="500"
                        dense>

                    </v-textarea>
                    </v-flex>
                    <v-flex xl12 lg12 md12 sm12 xs12 pa-2 class="mt-n5">
                        <b>{{$t('default') === 'en' ? 'Attach file' : 'แนบไฟล์'}}</b>
                         <v-card
                         outlined
                         :ripple="false"
                         class="py-2 px-2 mt-2"
                         @click="ImportFiles"
                         :disabled="isloadingUpload"
                         style="border-radius: 14px; border-color: #afafaf; overflow-y: scroll;"
                         >
                         <div v-if="isOpenStackFiles === false" style="min-height: 120px; max-height: 350px;">
                            <input ref="importimg"
                            class="d-none"
                            multiple
                            type="file" name="myImage" 
                            @change="fn_loadformfile"/>
                            <v-card-title class="text-center">
                            <v-layout column align-center justify-center >
                                <v-icon size="40">
                                    mdi-file-link-outline
                                </v-icon>
                            </v-layout>
                         </v-card-title>
                         <v-card-text class="text-center">
                            <span>
                                {{$t('default') === 'en' ? 'Clikc to upload' : 'คลิกเพื่ออัพโหลดไฟล์'}}
                                </span> <br />
                            <span v-if="errorFilesize" class="font-weight-bold" style="color: red;"> {{$t('default') === 'en' ? 'All files attached not more thane 25 MB' : 'ขนาดไฟล์แนบรวมกันต้องไม่เกิน 25 MB'}} </span>
                         </v-card-text>
                         </div>
                         <div v-else class="pa-2" style="min-height: 120px; max-height: 350px;">
                           <input ref="importimg"
                            class="d-none inputfiles"
                            multiple
                            type="file" name="myImage" 
                            @change="fn_loadformfile"/>
                           <v-layout row class="pa-1 pl-3 pr-3" align-center justify-center style="gap: 10px 30px; z-index: 2;" v-if="stack_filesShow.length > 1">
                                <v-flex row justify-center class="align-center elevation-1" v-for="(itemCard, index) in stack_filesShow" :key="index" 
                                    :style="`background-color: white; min-width: 80px;
                                    max-width: ${['xs', 'sm'].includes($vuetify.breakpoint.name) ? '200px' : '160px'}; border-radius: 25px; overflow: hidden;`">
                                   <v-flex xl2 lg2 md2 sm2 xs2 style="display: flex; justify-content: center;">
                                        <v-icon size="20" class="ml-2">
                                            {{ itemCard.icon[0] }}
                                        </v-icon>
                                   </v-flex>
                                   <v-flex xl8 lg8 md8 sm8 xs8
                                   style="display: flex;">
                                        <span v-if="['md', 'lg', 'xl'].includes($vuetify.breakpoint.name)" style="font-size: 12px; margin-left: 6px;">
                                            {{ itemCard.name  | subStr}}
                                        </span>
                                        <span v-else style="font-size: 12px; margin-left: 6px;">
                                            {{ itemCard.name  | longStr}}
                                        </span>
                                    </v-flex>
                                    <v-flex xl2 lg2 md2 sm2 xs2 class="pr-5">
                                        <v-btn @click="fn_deleteFiles($event, itemCard, index)" icon depressed>
                                            <v-icon size="16" class="ml-n4">
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </v-flex>
                                </v-flex>
                           </v-layout>
                           <v-layout row class="pa-1 pl-3 pr-3" align-center justify-center v-else>
                                <v-card outlined style="cursor: pointer; border-radius: 18px; min-width: 140px; position: relative;">
                                    <v-card-title style="border-radius: 18px;">
                                    <v-layout justify-center align-center>
                                    <v-btn icon style="position: absolute; right: 1px; top: 1px;" @click="fn_deleteFiles($event, stack_filesShow[0], 0)">
                                        <v-icon>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                    <v-icon size="60" > {{ stack_filesShow[0].icon[0] }} </v-icon>
                                    </v-layout>
                                    </v-card-title>
                                    <v-layout align-center justify-center class="py-3 px-4"  :style="`background-color: ${$vuetify.theme.dark ? '#464646' : '#FAFAFA'};`">
                                        <h4 style="font-size: 14px;" class="text-center">
                                            {{ stack_filesShow[0].name | ExtraLongStr }}
                                        </h4>
                                    </v-layout>
                                </v-card>
                                <!-- <v-flex row justify-center class="align-center elevation-1" v-for="(itemCard, index) in stack_filesShow" :key="index" 
                                    style="background-color: white; min-width: 80px;
                                    max-width: 160px; border-radius: 25px; overflow: hidden;">
                                   <v-flex xl2 lg2 md2 style="display: flex; justify-content: center;">
                                        <v-icon size="20">
                                            {{ itemCard.icon[0] }}
                                        </v-icon>
                                   </v-flex>
                                   <v-flex xl8 lg8 md8
                                   style="display: flex; text-overflow: ellipsis;">
                                        <span style="font-size: 12px;">
                                            {{ itemCard.name  | subStr}}
                                        </span>
                                    </v-flex>
                                    <v-flex xl2 lg2 md2 class="pr-5">
                                        <v-btn @click="fn_deleteFiles($event)" icon depressed>
                                            <v-icon size="16" class="ml-n4">
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </v-flex>
                                </v-flex> -->
                           </v-layout>
                            <!-- <v-flex row style="background-color: red; margin-left: 1px; overflow-x: scroll;">
                                <v-card v-for="(itemCard, index) in filesShow" :key="index">
                                    <v-card-title style="font-size: 12px;">
                                        {{ itemCard.name }}
                                    </v-card-title>
                                </v-card>
                            </v-flex> -->
                         </div>
                         </v-card>
                         <v-flex v-if="isOpenStackFiles && errorFilesize" row style="width: 100%;">
                            <v-spacer></v-spacer>
                            <span class="font-weight-bold mt-2" style="color: red;"> {{$t('default') === 'en' ? 'All files attached not more thane 25 MB' : 'ขนาดไฟล์แนบรวมกันต้องไม่เกิน 25 MB'}} </span>
                        </v-flex>
                    </v-flex>
                </v-layout>
                <!-- <v-layout row>
                    
                </v-layout> -->
            </v-card-text>
            <v-card-actions>
                <v-progress-circular 
                v-if="isloadingUpload"
                indeterminate
                size="24"
                width="4"
                :color="color.theme"
                />
                <v-spacer></v-spacer>
                <v-btn
                    color="red"
                    outlined
                    @click="fn_closedialogform(isloadingUpload ? 'CANCEL':'CLOSE')"
                    >{{ $t("checkpassword.cancel") }}</v-btn
                >
                <v-btn :disabled="btnDisabled_rp" 
                :color="color.theme" :dark="!btnDisabled_rp"
                @click="fn_uploadReport()">
                    {{$t('default') === 'en' ? 'Report' : 'รายงานปัญหา'}} 
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- <v-dialog
    v-else
    >

    </v-dialog> -->

  </v-row>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters, mapActions } from "vuex";
import OtpInput from "otp-input-vue2";
import { validationMixin } from "vuelidate";
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
const validatePhoneNumber = helpers.regex('validatePhone', /^0[0-9]+$/ )
export default {
    mixins: [validationMixin],
    validations: {
        email_rp: { required, email },
        username_rp: {required},
        detail_rp: { minLength: minLength(0), maxLength: maxLength(500)},
        phonenumber: {validatePhoneNumber}
    },
    components: {
        OtpInput,
    },
    props: ["show", "biz_nam", "topicCase", "username_account"],
    computed: {
        ...mapState(["username", "authorize", "account_active", "service", "color"]),
        ...mapState({ processloader: "loading" }),
        ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
        "dataStorageUsage",
        "dataStorageMax",
        ]),
        resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
            case "xs":
            return 220;
            case "sm":
            return 400;
            case "md":
            return 500;
            case "lg":
            return 600;
            case "xl":
            return 800;
            }
      },
      maxWidthOnMobile() {
        if(this.resolutionScreen >= 400) {
            return 600
        } else {
            return 346
        }
    },
    email_report() {
      const errors = [];
      if (!this.$v.email_rp.$dirty) return errors;
      !this.$v.email_rp.required &&
        errors.push(this.$t('default') === 'en' ? "Please fill an email." : "โปรดระบุอีเมล");
      !this.$v.email_rp.email &&
        errors.push(this.$t("sendtoemail.invalidemailformat"));
      return errors;
    },
    username_report() {
      const errors = [];
      if (!this.$v.username_rp.$dirty) return errors;
      !this.$v.username_rp.required &&
        errors.push(this.$t('default') === 'en' ? " Please fill a name." : "โปรดระบุชื่อจริง");
      return errors;
    },
    phonenumber_report() {
      const errors = [];
      if (this.isPhoneNumber) {
        errors.push(this.$t('default') === 'en' ? "Please enter your phone number correctly." : "โปรดระบุเบอร์โทรศัพท์ให้ครบ 10 หลัก");
      }
      if (!this.$v.phonenumber.$dirty) { console.log("") }
      if(this.$v.phonenumber.$invalid){
        if(this.phonenumber.length > 1){
            errors.push(this.$t('default') === 'en' ? "Please enter your phone number correctly (Start with 0)." : "โปรดระบุเบอร์โทรศัพท์ให้ถูกต้อง (ขึ้นต้นด้วย 0)");
        }
        
      }
      return errors;
    },
    topic_report () {
        const errors = []
        if(this.isTopicNull){
            errors.push(this.$t('default') === 'en' ? "Please select a report topic." : "โปรดเลือกหัวข้อปัญหา");
        }
        return errors;
    },
    detail_report() {
      const errors = [];
      if (!this.$v.detail_rp.$dirty) return errors;
      !this.$v.detail_rp.maxLength &&
        errors.push(this.$t('default') === 'en' ? "Please fill detail not more than 500 characters." : "โปรดใส่รายละเอียดไม่เกิน 500 ตัวอักษร");
      return errors;
    },
    btnDisabled_rp () {
        if(this.isloadingUpload){
            return true
        } else {
            return false
        }
    }
    },
    data () {
        return {
            username_rp: "",
            phonenumber: "",
            email_rp: "",
            detail_rp: "",
            testtOTP: "",
            bizName: "",
            totalSize: 0,
            unitSize: "",
            cancelToken_source: "",
            isTopicNull: false,
            isPhoneNumber: false,
            isloadingUpload: false,
            isOpenStackFiles: false,
            errorFilesize: false,
            selectedTopic_default: [],
            stack_filesProcress: [],
            stack_filesShow: [],
            itemsTopic: [
                "ทั้วไป",
                "ฟีทเจอร์พิเศษ",
                "วิธีการใช้งาน",
                "พื้นที่การใช้งาน",
                "การใช้งาน E-TAX Invoice",
                "สิทธิ์การใช้งานไฟล์/โฟลเดอร์",
                "อื่นๆ"
            ],
            rules: {
            length: len => v => (v || '').length <= len || `Invalid character length, required ${len}`
            }
        }
    },
    filters: {
    subStr: function(val) {
        if (val.length > 15) return val.substring(0, 13) + "...";
            else return val;
        },
    longStr: function(val) {
        if (val.length > 15) return val.substring(0, 16) + "...";
            else return val;
        },
    ExtraLongStr: function(val) {
        if (val.length > 40) return val.substring(0, 30) + "...";
            else return val;
        },
    },
    watch: {
        show: function (val) {
            if(val){
                console.log("SHOW top ", this.topicCase)
                this.bizName = this.biz_nam
                this.selectedTopic_default = this.topicCase[0]
                this.username_rp = `${this.$t('default') === 'th' ? this.username_account.first_name_th : this.username_account.first_name_eng} `+
                    `${this.$t('default') === 'th' ? this.username_account.last_name_th : this.username_account.last_name_eng}`
            }
        },
        selectedTopic_default:  function (newVal, oldVal) {
            if(typeof newVal === 'object'){
                this.isTopicNull = false  
            }
        },
    },
    methods: {
        fn_unfocus_phonenumber () {
            if(this.phonenumber.length >= 1 && this.phonenumber.length < 10){
                console.log("ทำไมไม่ใส่ให้ครบหล่ะเห้ย ")
                this.isPhoneNumber = true
            } else {
                this.isPhoneNumber = false
            }
        },
        fn_keyupPhoneNumber (event) {
            event = (event) ? event : window.event;
            let expect = event.target.value.toString() + event.key.toString();
            
            if (!/^[]?[0-9]*\.?[0-9]*$/.test(expect) || this.phonenumber.length === 10) {
                console.log("stop 1")
                event.preventDefault();
            } else {
                return true;
            }
        },
        // DEL FILES
        fn_deleteFiles (e, fileItem, indexFile) {
            e.stopPropagation() // NOT TRIGGLED INPUT FILE
            if(this.errorFilesize){
                this.errorFilesize = false
            }
            console.log("files ", fileItem)
            let forSpliceArray_Files = Array.from(this.stack_filesProcress)
            forSpliceArray_Files.splice(indexFile, 1) // REMOVE SELECTED FILE IN STACK PROCESS
            this.stack_filesProcress = forSpliceArray_Files // UPDATES
            this.stack_filesShow.splice(indexFile, 1) // REMOVE SELECTED FILE IN STACK SHOW
            this.totalSize -= fileItem.size  // DECREASE TOTAL SIZE FILES
            if(this.stack_filesProcress.length === 0 || this.stack_filesShow.length === 0){
                this.isOpenStackFiles = false
                this.totalSize = 0
                return
            }
        },
        // UPLOAD FILES
        async fn_uploadReport () {
            if(this.username_rp === "" || this.$v.username_rp.$error){
                console.log("HRRE")
                this.$v.username_rp.$touch()
                return
            }
            if(this.$v.email_rp.$error || this.email_rp === ""){
                this.$v.email_rp.$touch()
                return
            }
            if(this.$v.detail_rp.$error || this.isPhoneNumber){
                return
            }
            if(typeof this.selectedTopic_default !== 'object') { // เพราะว่าไม่ใ
                this.isTopicNull = true
                return
            }
            let formDataCreate = new FormData()
            //formDataCreate.append("business_id", this.dataAccountActive["type"] === "Business" ? this.dataAccountActive.business_info.business_id : "")
            formDataCreate.append("account_id", this.dataAccountId)
            formDataCreate.append("case_id",this.selectedTopic_default.case_id )
            formDataCreate.append("detail", this.detail_rp)
            formDataCreate.append("name", this.username_rp)
            formDataCreate.append("tel_no", this.phonenumber)
            formDataCreate.append("email", this.email_rp)
            for(var i = 0; i < this.stack_filesProcress.length; i++){
                console.log("Adding files ", this.stack_filesProcress[i])
                formDataCreate.append("files", this.stack_filesProcress[i])
            }
            // console.log("READY FORMDATA ", formDataCreate)
            this.isloadingUpload = true
            const CancelToken = this.axios.CancelToken;
            let auth = await gbfGenerate.generateToken();
            this.cancelToken_source = CancelToken.source();
            await this.axios({
                method: "POST",
                url: process.env.VUE_APP_SERVICE_ADMIN + "/api/v1/add_case_ticket",
                cancelToken: this.cancelToken_source.token,
                data: formDataCreate,
                headers: { Authorization: auth.code },
            })
            .then((res) => {
                if(res.data.status === 'OK'){
                    Toast.fire({
                    icon: "success",
                    title: "อัพโหลดรายงานสำเร็จ",
                    });
                    this.fn_closedialogform('SUCCESS') // CLOSE AND CLEAR DIALOG
                } else {
                    Toast.fire({
                    icon: "error",
                    title: "อัพโหลดรายงานไม่สำเร็จ",
                    });
                    this.isloadingUpload = false
                }
            })
            .catch((err) => {
                console.log("ERROR CATCH ", err)
                if(this.axios.isCancel(err)){
                    console.log("CANCEL ERROR ", err)
                    Toast.fire({
                        icon: "error",
                        title: "ยกเลิกอัพโหลดรายงาน",
                    });
                    return
                }
                Toast.fire({
                    icon: "error",
                    title: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
                });
                this.fn_closedialogform('CLOSE')
            })
            
        },
        fn_closedialogform (type) {
            if(type === 'CANCEL'){
                this.cancelToken_source.cancel("CANCEL_UPLOAD")
                this.isloadingUpload = false
                return
            } else if (type === 'CLOSE' || type === 'SUCCESS') {
                this.$emit('closedialog')
                setTimeout(() => {
                    this.$v.email_rp.$reset()
                    this.$v.username_rp.$reset()
                    this.$v.detail_rp.$reset()
                    // CLEAR ALL 
                    this.fn_cleardata()
                }, 200)
            }
            
        },
        fn_loadformfile (e) {
            // FIRST TIME SELECT FILES TO UPLOAD
            if(this.stack_filesProcress.length === 0 || this.stack_filesShow.length === 0){
            this.errorFilesize = false
            let totalSize_file = 0
            let fileList_ = e.target.files
            // INCLUDES NEW SIZE FILES
            for(var i = 0; i < fileList_.length; i++){
                totalSize_file += fileList_[i].size   
            }
            // CHECK SIZE OF FILES FIRST TIME
            this.fn_checksizefiles(this.calculatesize(totalSize_file))
            .then((res) => {
                if(res.code === "ACCEPT_FILES"){ // INCLUDED NOT MORE THAN 500 MB
                    console.log("ACCEPTED TOTAL SIZE FILES ", res.sizeFormat)
                    this.totalSize += totalSize_file // KEEP SIZE FILES
                    this.stack_filesProcress = Array.from(e.target.files)
                    this.isOpenStackFiles = true
                    for(var i = 0; i < fileList_.length; i++){
                        let fileDataObj = {}
                        let typeFileSplited = fileList_[i].name.split(".")
                        fileDataObj['name'] = fileList_[i].name
                        fileDataObj['size'] = fileList_[i].size
                        fileDataObj['icon'] = this.seticon(fileList_[i].name.split(".")[typeFileSplited.length - 1])
                        this.stack_filesShow.push(fileDataObj)
                    }
                    // console.log("FILES SHOW ", this.stack_filesShow)
                } else {
                    console.log("NOT ACCEPTED TOTAL SIZE FILES ", res.sizeFormat)
                    this.errorFilesize = true
                }
            })
            } 
            // AFTER ALEADY STACK FILES
            else {
                let current_fileList_ = Array.from(e.target.files)
                //** */ MUST TO CHECK EXIST FILES IN STACK
                let checkedFileList_ = [...this.fn_check_existFiles_stack(this.stack_filesProcress, current_fileList_)]
                if(checkedFileList_.length === 0){
                    console.log("ALL THESE FILES ALEADY EXIST IN STACK FILES")
                    return
                }
                // INCLUDES NEW SIZE FILES
                let sizeFile_continue = 0
                for(var i = 0; i < checkedFileList_.length; i++){
                    sizeFile_continue += checkedFileList_[i].size   
                }
                sizeFile_continue += this.totalSize
                // CHECK SIZE OF FILES WITH CURRENT SIZE IN STACK
                this.fn_checksizefiles(this.calculatesize(sizeFile_continue))
                .then((res) => {
                    if(res.code === "ACCEPT_FILES"){ // INCLUDED NOT MORE THAN 500 MB
                        console.log("ACCEPTED TOTAL SIZE FILES ", res.sizeFormat)
                        this.errorFilesize = false
                        this.totalSize = sizeFile_continue
                        this.stack_filesProcress = [...this.stack_filesProcress, ...checkedFileList_]
                        for(var i = 0; i < checkedFileList_.length; i++){
                        let fileDataObj = {}
                        let typeFileSplited = checkedFileList_[i].name.split(".")
                        fileDataObj['name'] = checkedFileList_[i].name
                        fileDataObj['size'] = checkedFileList_[i].size
                        fileDataObj['icon'] = this.seticon(checkedFileList_[i].name.split(".")[typeFileSplited.length - 1])
                        this.stack_filesShow.push(fileDataObj)
                        }
                    } else { // INCLUDED MORE THAN 500 MB
                        console.log("NOT ACCEPTED TOTAL SIZE FILES ", res.sizeFormat)
                        this.errorFilesize = true
                    }
                })
            }
        },
        fn_checksizefiles (sizefile) {
            return new Promise((resolve, reject) => {
                let pureNumber = sizefile.split(' ')
                if(pureNumber.findIndex((item) => item === 'B') === 1){
                    resolve({code: "ACCEPT_FILES", sizeFormat: pureNumber})
                }
                if(pureNumber.findIndex((item) => item === 'KB') === 1){
                    resolve({code: "ACCEPT_FILES", sizeFormat: pureNumber})
                }
                if(pureNumber.findIndex((item) => item === 'MB') === 1){  // CHECK MB UNIT
                let numSize = Math.ceil(new Number(pureNumber[0]))
                if(numSize > 25){ // MORE THAN 500
                    resolve({code: "NO_ACCEPT_FILES", sizeFormat: pureNumber})
                } else {
                    resolve({code: "ACCEPT_FILES", sizeFormat: pureNumber})
                }
                } else { // PREDICT -> GB
                    resolve({code: "NO_ACCEPT_FILES", sizeFormat: pureNumber})
                }
            })
        },
        fn_cleardata () {
            this.username_rp = ""
            this.phonenumber = ""
            this.email_rp = ""
            this.detail_rp = ""
            this.totalSize = 0
            this.isTopicNull = false
            this.isPhoneNumber = false
            this.errorFilesize = false
            this.isloadingUpload = false
            this.isOpenStackFiles = false
            this.stack_filesShow = []
            this.stack_filesProcress = []
        },
        fn_check_existFiles_stack (filesPrevStack, filesCurStack) {
            return filesCurStack.filter(itemCur => !filesPrevStack.some(itemPrev => itemPrev.name === itemCur.name && itemPrev.size === itemCur.size))
        },
        ImportFiles() {
        //    this.fileImport = null;
           this.$refs.importimg.value = null;
           window.addEventListener(
           "focus",
           () => {
               this.selectingImportFiles = false;
           },
           { once: true }
           );
           this.$refs.importimg.click();
        },
        // คำนวณขนาดไฟล์
        calculatesize(_sizefilebyte) {
        let size;
        if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
            size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
        } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
            size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
        } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
            size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
        } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
            size = (_sizefilebyte / 1024).toFixed(2) + " KB";
        } else if (_sizefilebyte === "-") {
            size = _sizefilebyte;
        } else {
            size = _sizefilebyte + " B";
        }
        return size;
        },
        seticon(parameter) {
        let dataicon;
        if (parameter === "xlsx" || parameter === "xls") {
            dataicon = ["mdi-file-excel", "#00733b"];
        } else if (parameter === "docx" || parameter === "doc") {
            dataicon = ["mdi-file-word", "#0263d1"];
        } else if (parameter === "pptx" || parameter === "ppt") {
            dataicon = ["mdi-file-powerpoint", "#e03303"];
        } else if (parameter === "pdf") {
            dataicon = ["mdi-file-pdf", "#e5252a"];
        } else if (parameter === "txt" || parameter === "txt") {
            dataicon = ["mdi-note-text", "blue-grey"];
        } else if (
            parameter === "jpeg" ||
            parameter === "jpg" ||
            parameter === "png"
        ) {
            dataicon = ["mdi-file-image", "#0ac963"];
        } else if (
            parameter === "avi" ||
            parameter === "mpeg" ||
            parameter === "mov" ||
            parameter === "mp4" ||
            parameter === "mkv" ||
            parameter === "wmv" ||
            parameter === "3gp" ||
            parameter === "flv"
        ) {
            dataicon = ["mdi-file-video", "purple"];
        } else if (parameter === "shortcut") {
            dataicon = ["mdi-file-link", "pink"];
        } else if (parameter === "r" || parameter === "folder") {
            dataicon = ["mdi-folder", "#ffc107"];
        } else {
            dataicon = ["mdi-file", "black"];
        }

        return dataicon;
        },
        onChangedOTP(e) {
            this.$refs['otpVerifyRefs'].inputValue[0].toUpperCase()
            document.getElementsByClassName('otp-input')[0].value.toUpperCase()
            console.log("SSS ", document.getElementsByClassName('otp-input'))
            console.log("TEMP ", this.$refs['otpVerifyRefs'].inputValue[0].toUpperCase())
        },
        uppercase (e) {
            console.log("UPPERCASE ", e)
        }
    }
}
</script>

<style scoped>
.v-card--link:focus {
  background: none;
}
.v-card--link:active {
    background-color: white !important;
}

.v-card--link:focus::before {
  opacity: 0;
}
.inputfiles:focus{
    background-color: red;
}
input[type=file] {
  border: none;        /* Remove default borders */
  padding: 4px 8px;
  border-radius: 12px; /* Sadly outline does not round! therefore...*/
}
input[type=file]:focus{
  outline: none;      /* Remove default outline and use border or box-shadow */
  box-shadow: 0 0 0 2px orange; /* Full freedom. (works also with border-radius) */
}
</style>